.text-block {
  ul {
    @apply list-decimal pl-6 mb-4;
  }
  ol {
    @apply list-disc pl-6 mb-4;
  }
  h1, h2, h3, h4, h5, h6  {
    @apply font-semibold leading-relaxed mb-4;
  }
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3, h4, h5, h6 {
    @apply text-lg;
  }
}