img {
  @apply w-full max-w-full
}

.safe {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.safe-sides {
  padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

.safe-top {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

.safe-bottom {
  padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.text-block p {
  @apply mb-4;
}

.content-wrapper {
  @apply p-6 md:p-12 max-w-4xl m-auto;
}

:root {
  --color-primary: 127 127 127;
  --ion-background-color: #f6f6f6;
  --ion-tab-bar-background-color: #fff;
}
@media (prefers-color-scheme: dark) {
  :root {
    --ion-background-color: #1aa2aa;
    --ion-tab-bar-background-color: #0a0a0a;
  }
}

.text-block a {
  color: #0091bc;
}

.text-block a:hover {
  text-decoration: underline;
}

.article-wrapper {
  @apply p-6 md:p-12 max-w-4xl m-auto;
  //background-color: #f5f5f5;
}